import React, { Component } from "react";
import ReactPasswordStrength from "react-password-strength";
import myConfig from "../../configs/config";
import axios from "axios";
import { withTranslation } from "react-i18next";
import ResetDone from "./ResetDone";
import { ToastContainer, toast } from "react-toastify";
import MDSpinner from "react-md-spinner";
import { Link } from "react-router-dom";

class ResetPasswordDone extends Component {
  constructor(props) {
    super(props);
    this.state = {
      new_password: "",
      confirm_new: "",
      email_done: false,
      loading: false,
      expirated_tk: false,
    };
    this.handleChangeText = this.handleChangeText.bind(this);
    this.handleSave = this.handleSave.bind(this);
  }

  async checkToken() {
    try {
      let CHECKED_TOKEN_URL = `${myConfig.API_URL}/accounts/valid_hash/?tk=${this.props.match.params.token}`;

      let response = await axios({
        baseURL: CHECKED_TOKEN_URL,
        method: "get",
      });

      // console.log(response.data)
      if (response.status === 200) {
        this.setState({
          loading: false,
        });
      }
    } catch (error) {
      // console.log(error.response.data.status);
      this.setState({
        loading: false,
        expirated_tk: true,
      });
    }
  }

  handleChangeText(e, name) {
    let state = {};
    state[name] = e.password;
    this.setState(state);
  }

  getFormatData() {
    let form_data = new FormData();
    form_data.append("new_password", this.state.new_password);
    form_data.append("confirm_new", this.state.confirm_new);
    form_data.append("id", this.props.match.params.token);

    return form_data;
  }

  handleSave(e) {
    e.preventDefault();
    const PASSWORD_URL = myConfig.API_URL + "/accounts/set_reset_password/";

    if (this.state.new_password !== this.state.confirm_new) {
      let error_msg =
        "Nova senha e Confirmar senha devem ser exatamente iguais.";

      toast.error(error_msg, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      return;
    }
    if (this.state.new_password === "" || this.state.confirm_new === "") {
      let error_msg = "Preencha todos os campos para realizar a solicitação";

      toast.error(error_msg, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      return;
    }

    this.setState({
      loading: true,
    });

    axios({
      baseURL: PASSWORD_URL,
      method: "put",
      data: this.getFormatData(),
    })
      .then((response) => {
        if (response.status === 200) {
          setTimeout(() => {
            this.setState({
              email_done: true,
            });
          }, 1500);
        }
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });
        let error_msg = error.response.data.status;

        toast.error(error_msg, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  }

  RedirectToPage() {
    return (
      <div className="row">
        <div className="col-md-offset-4 col-md-4 col-xs-12">
          <div className="x_panel" style={{ borderRadius: "5px" }}>
            <div className="x_content" align="center">
              <p>{this.props.t("Token expirado, deseja solicitar um novo?")}</p>

              <p>
                <Link to="/account/recovery/" class="btn btn-primary">
                  {this.props.t("Clique aqui")}
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  Loading() {
    return (
      <div
        style={{
          marginTop: "50px",
          marginLeft: "50%",
          minHeight: "200px",
        }}
      >
        <MDSpinner />
      </div>
    );
  }

  FormResetPassword() {
    return (
      <div className="row">
        <div className="col-md-offset-4 col-md-4 col-xs-12">
          <div className="x_panel" style={{ borderRadius: "5px" }}>
            <div className="x_content" align="center">
              <h2>{this.props.t("Digite a nova senha")}</h2>

              <form
                method="POST"
                onSubmit={this.handleSave}
                encType="multipart/form-data"
              >
                <label htmlFor="new_password">
                  {this.props.t("Nova senha")}
                </label>
                <ReactPasswordStrength
                  minLength={4}
                  minScore={2}
                  scoreWords={[
                    "Fraca",
                    "Razoável",
                    "Bom",
                    "Forte",
                    "Muito forte",
                  ]}
                  changeCallback={(event) =>
                    this.handleChangeText(event, "new_password")
                  }
                  inputProps={{
                    name: "new_password",
                    autoComplete: "off",
                    className: "form-control pass-strength",
                    placeholder: "Digite aqui sua nova senha",
                  }}
                />
                <label htmlFor="confirm_new">
                  {this.props.t("Confirmar senha")}
                </label>
                <ReactPasswordStrength
                  minLength={4}
                  minScore={2}
                  scoreWords={[
                    "Fraca",
                    "Razoável",
                    "Bom",
                    "Forte",
                    "Muito forte",
                  ]}
                  changeCallback={(event) =>
                    this.handleChangeText(event, "confirm_new")
                  }
                  inputProps={{
                    name: "confirm_new",
                    autoComplete: "off",
                    className: "form-control pass-strength",
                    placeholder: "Confirme aqui sua nova senha",
                  }}
                />{" "}
                <br />
                <div align="center">
                  <input
                    type="submit"
                    className="btn btn-primary"
                    value="Salvar"
                  />
                </div>
              </form>
            </div>{" "}
          </div>{" "}
        </div>{" "}
        <ToastContainer />
      </div>
    );
  }
  render() {
    let { expirated_tk, email_done, loading } = this.state;
    let content;

    if (email_done) {
      content = <ResetDone />;
    } else if (expirated_tk) {
      content = this.RedirectToPage();
    } else if (loading) {
      content = this.Loading();
    } else {
      content = this.FormResetPassword();
    }

    return <div id="profile_reset_password">{content}</div>;
  }
}
export default withTranslation()(ResetPasswordDone);
